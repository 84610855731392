.payment-methods {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr auto;
  padding: 2rem;
  gap: 1.25rem;
  grid-template-areas: 
  "nav nav nav hd hd hd hd hd hd hd hd hd"
  ". . . main main main main main main . . ."
  "ft ft ft ft ft ft ft ft ft ft ft ft";
  height: 100%;
  overflow-y: scroll;
}

.payment-methods__header {
  grid-area: hd;
  display: flex;
  justify-content: space-between;
}

.payment-methods__nav {
  grid-area: nav;
}

.payment-methods__main {
  grid-area: main;
  height: fit-content;
  margin: 1rem 0 0 1.25rem;
  background-color: var(--goto-background-color-default);
  border: 1px solid var(--goto-border-color-default);
  border-width: 8px 1px 1px;
  border-radius: var(--goto-radius-medium);
  border-top-color: var(--goto-brand-color-admin-blue);
  padding: 1.5rem;
}
