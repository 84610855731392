.session-expired-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.session-expired-modal__header {
  margin: 1.25rem 0;
}

.session-expired-modal__message {
  text-align: center;
  margin-bottom: 1.25rem;
}
