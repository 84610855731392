.credit-card-save-button {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}

.credit-card-save-button__cancel {
  margin-right: var(--goto-spacing-06);
}

