.card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-info__icon-num {
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
  align-items: center;
}
