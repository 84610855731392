.payment-method-options {
  margin: 1.5rem 0;
  display: flex;
}

.payment-method-options__skeleton {
  margin-top: 1.5rem;
  width: 100%;
}

.payment-method-options__radio {
  display: flex;
  padding: 1.5rem;
  align-items: center;
  border: 1px solid var(--goto-border-color-default);
  border-radius: 0.5rem;
  gap: 1rem;
  cursor: pointer;
}

.payment-method-options__type {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
}
