.paypal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paypal-info__details {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
