.payment-methods-available {
  margin: 1.5rem 0;
  display: flex;
}

.payment-methods-available__skeleton {
  margin-top: 1.5rem;
  width: 100%;
}

.payment-methods-available__radio {
  border-top: 1px solid var(--goto-border-color-default);
  padding: 1rem 0.5rem;
}

.payment-methods-available__radio:last-child {
  border-bottom: 1px solid var(--goto-border-color-default);
}

.payment-methods-available__radio::part(label) {
  width: 100%;
  margin-left: 0.5rem;
}

.payment-methods-available__add {
  margin-bottom: 1rem;
}
