.price-info__list {
  list-style: none;
  padding: 0;
}

.price-info__list > li {
  margin-bottom: 0.5rem;
}

.price-info-email {
  word-break: break-all;
}

.price-info__separater {
  margin: 1rem 0 0.5rem;
}

.price-info__total {
  display: flex;
  justify-content: space-between;
}