.direct-debit-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.direct-debit-info__details {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
