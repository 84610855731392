.credit-card-number__label {
  margin-bottom: 2px;
}

.credit-card-number__input {
  position: relative;
}

.credit-card-number__field {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
}

.credit-card-number__error {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
  border-color: var(--goto-input-danger-border) !important;
}

.credit-card-number__focused {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
  border-color: var(--goto-focus);
  box-shadow: var(--goto-highlight-small);
}

.credit-card-number__icon {
  position: absolute;
  top: 3px;
  right: 50px;
}


.credit-card-number__icon--dark {
  position: absolute;
  top: 3px;
  right: 50px;
  background-color: black;
}