.default-delete-ctas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.default-delete-ctas__primary {
  background-color: var(--goto-surface-color-inverted);
}

.default-delete-ctas__chip-text {
  margin-bottom: 0.1rem;
}
