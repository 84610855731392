.credit-card-cvv {
  padding: 0 1rem;
  flex: 0 0 50%;
}

.credit-card-cvv__label {
  margin-bottom: 2px;
}

.credit-card-cvv__field {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
}

.credit-card-cvv__error {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
  border-color: var(--goto-input-danger-border) !important;
}

.credit-card-cvv__focused {
  height: 35px;
  border-radius: 4px;
  padding: 0 12px;
  border: 1px solid var(--goto-border);
  background-color: var(--goto-ui-01);
  color: var(--goto-text-01);
  font: var(--goto-body-medium);
  border-color: var(--goto-focus);
  box-shadow: var(--goto-highlight-small);
}

