@import '@getgo/chameleon-core/css/gotoadmin.css';

html {
  overflow: hidden;
}

html, body {
  height: 100%;
}

[data-color-scheme='dark'] {
  color-scheme: dark;
}

[data-color-scheme='light'] {
  color-scheme: light;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.root, .app__theme-provider {
  height: inherit;
}