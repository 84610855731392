.feedback__menu-separator {
  width: 108%;
}

.feedback__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback__success-message {
  text-align: center;
}

.feedback__failure-info {
  margin: 1.5rem 0;
}

.feedback__ctas {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

.feedback__body-info {
  margin: var(--goto-spacing-06) 0;
}

