.direct-debit-mandate__info {
  padding: 0 1rem 0 0.5rem;
}

.direct-debit-mandate__alert {
  margin: 0.75rem 0;
}

.direct-debit-mandate__user-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0.75rem 0;
  padding: 0.625rem 0;
}

.direct-debit-mandate__creditor-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0.625rem 0;
}

.direct-debit-mandate__check-box {
  margin: 1.25rem 0;
}

.direct-debit-mandate__tnc-checkbox::part(control) {
  margin-top: 0.5rem;
}

.direct-debit-mandate__cta {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.direct-debit-mandate__cancel-cta {
  margin-right: 0.5rem;
}